@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Gotham";
  src: url(/static/media/Gotham_Black.57033dc1.otf) format("opentype");
}

.card {
  position: relative;
}

.card::before {
  content: "";
  overflow: hidden;
  background: #1b9aaa;
  border-radius: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  -webkit-transform: translate(8px, 8px);
          transform: translate(8px, 8px);
  transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1);
}

.card:hover:not(.expanded)::before {
  -webkit-transform: translate(14px, 14px);
          transform: translate(14px, 14px);
}

.collage * {
  border-radius: 15px;
  border: 0 !important;
}

.ui.button.button {
  color: #464646;
  font-family: Montserrat;
  font-weight: 500;
}

.ui.label.label {
  margin: 0.2em;
  font-size: 1.1vw;
}

@media (min-width: 1245px) {
  .ui.label.label {
    font-size: 0.8rem;
  }
}

.ui.animated.button .visible.content {
  margin-right: 0.6em !important;
}

.ui.animated.button .hidden.content {
  font-size: 7px !important;
}

.ui.button.button {
  padding: 1vw 0.8em 1vw;
  font-size: 1rem;
}

@media (min-width: 650px) {
  .ui.animated.button .visible.content {
    margin-right: 1em !important;
  }
}

@media (min-width: 650px) {
  .ui.animated.button .hidden.content {
    font-size: 12px !important;
  }
}

@media (min-width: 650px) {
  .ui.button.button {
    padding: 0.78571429em 1.2em 0.78571429em;
    font-size: 1rem;
  }
}

@media (min-width: 1575px) {
  .ui.animated.button .visible.content {
    margin-right: 1.5em !important;
  }
}

@media (min-width: 1575px) {
  .ui.button.button {
    padding: 0.78571429em 1.5em 0.78571429em;
    font-size: 1rem;
  }
}

@media (min-width: 1575px) {
  .ui.animated.button .hidden.content {
    font-size: 16px !important;
  }
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #464646;
  border-radius: 25px;
  opacity: 0.3;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #464646;
  -webkit-animation-name: scroll;
          animation-name: scroll;
  -webkit-animation-duration: 2.2s;
          animation-duration: 2.2s;
  -webkit-animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
          animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
@-webkit-keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
}

