@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

@font-face {
  font-family: "Gotham";
  src: url("./Gotham_Black.otf") format("opentype");
}

.card {
  position: relative;
}

.card::before {
  content: "";
  overflow: hidden;
  background: #1b9aaa;
  border-radius: 15px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 0;
  transform: translate(8px, 8px);
  transition: transform 0.4s ease;
  box-shadow: 0 25px 25px rgba(0, 0, 0, 0.1);
}

.card:hover:not(.expanded)::before {
  transform: translate(14px, 14px);
}

.collage * {
  border-radius: 15px;
  border: 0 !important;
}

.ui.button.button {
  color: #464646;
  font-family: Montserrat;
  font-weight: 500;
}

.ui.label.label {
  margin: 0.2em;
  font-size: 1.1vw;
}

@media (min-width: 1245px) {
  .ui.label.label {
    font-size: 0.8rem;
  }
}

.ui.animated.button .visible.content {
  margin-right: 0.6em !important;
}

.ui.animated.button .hidden.content {
  font-size: 7px !important;
}

.ui.button.button {
  padding: 1vw 0.8em 1vw;
  font-size: 1rem;
}

@media (min-width: 650px) {
  .ui.animated.button .visible.content {
    margin-right: 1em !important;
  }
}

@media (min-width: 650px) {
  .ui.animated.button .hidden.content {
    font-size: 12px !important;
  }
}

@media (min-width: 650px) {
  .ui.button.button {
    padding: 0.78571429em 1.2em 0.78571429em;
    font-size: 1rem;
  }
}

@media (min-width: 1575px) {
  .ui.animated.button .visible.content {
    margin-right: 1.5em !important;
  }
}

@media (min-width: 1575px) {
  .ui.button.button {
    padding: 0.78571429em 1.5em 0.78571429em;
    font-size: 1rem;
  }
}

@media (min-width: 1575px) {
  .ui.animated.button .hidden.content {
    font-size: 16px !important;
  }
}

.mousey {
  width: 3px;
  padding: 10px 15px;
  height: 35px;
  border: 2px solid #464646;
  border-radius: 25px;
  opacity: 0.3;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #464646;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}
